import React, { useState, useEffect } from "react"
import { Link } from 'react-router-dom';

const Header = () => {
  const [logo, setLogo] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://roadmaps-backend-3b753e6da0ee.herokuapp.com/roadmaps-logo.png', {mode:'cors'});
      
      console.log(result.url)
      setLogo(result.url);
    };

    fetchData();
  }, []);


  return (
    <header style={{ backgroundColor: '#000000',  position: 'sticky'}}>
      <nav>
        <ul style={{ listStyle: 'none', display: 'flex', padding:'10px 10px 10px 10px'}}>
          <li>
            <Link to="/">
            <img src={logo} alt="ROADMAPS.FYI" width={240} height={40}></img>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;