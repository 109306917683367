import React, { useState, useEffect } from "react"
import { Card, Row, Col, Container } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { FaDownload } from "react-icons/fa";

const Tile = () => {
  const [tileList, setTiles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetch('https://roadmaps-backend-3b753e6da0ee.herokuapp.com/tiles', {mode:'cors'});
      
      const jsonResult = await result.json();
      console.log(jsonResult)
      setTiles(jsonResult);
    };

    fetchData();
  }, []);

  const downloadImage = (url, clickable, text) => {
    console.log(url)
    console.log(text)
    if (clickable) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            // Create a temporary anchor element and set its attributes
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = text; // Specify the filename for the downloaded file
    
            // Simulate a click on the link to trigger the download
            link.click();
    
            // Clean up the URL.createObjectURL() resource after the download is initiated
            URL.revokeObjectURL(link.href);
          })
          .catch((error) => {
            console.error('Error while fetching the file:', error);
          });
      }
}

  return (
    <Container>
      <Row style={{ flexzdirection: 'row' }}>
        {tileList.map((tileItem, index) => (
          <Col key={index} xs={6} md={5} lg={3} style={{ display: 'flex', alignItems: 'center', alignItems: 'stretch', padding: '10px' }}>
            <Card style={{ padding: '20px 0 15px 0' }}>
              <Card.Title style={{ fontSize: '18px', fontWeight: 'bold' }}>{tileItem.text}</Card.Title>
              <Card.Body>
                <Link to={{ pathname: `/${tileItem.path}/${tileItem.id}`, state: { tileItem: tileItem } }}>
                  <Card.Img variant="top" style={{ aspectRatio: 1, border: '1px solid #ccc' }} src={'https://roadmaps-backend-3b753e6da0ee.herokuapp.com/'+tileItem.imgRef} alt="Image"/>
                </Link>
              </Card.Body>
              <div>
                <FaDownload onClick={() => downloadImage(`https://roadmaps-backend-3b753e6da0ee.herokuapp.com/${tileItem.flow}`, tileItem.clickable, tileItem.text)}></FaDownload>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Tile;