import Tile from './Tile';

import ContactForm from './ContactForm';

function HomePage() {
    return (
        <div>
            <br />
            <Tile />
            <br />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <ContactForm />
            </div>
            <br />
            <br />
        </div>
    );
}

export default HomePage;