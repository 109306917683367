import { Form, Button, FormGroup } from "react-bootstrap";
import { useState } from 'react'
import axios from 'axios';
import React from 'react'

const ContactForm = () => {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [characterLimitExceeded, setCharacterLimitExceeded] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const url = 'https://roadmaps.fyi/databaseconnect.php';
    let fData = new FormData();
    fData.append('message', message);

    axios
      .post(url, fData)
      .then((response) => {
        console.log(response.data);
        if (message.length <= 200) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 1000);
          setCharacterLimitExceeded(false);
        } else {
          setSuccess(false);
          setCharacterLimitExceeded(true);
        }
        setMessage('');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Form style={{ width: '40%', position: 'relative' }} onSubmit={handleSubmit} noValidate>
      <Form.Group className="mb-3" controlId="feedback">
        <div style={{ display: 'flex' }}>
          <Form.Control
            type="textarea"
            placeholder="Improvements? Missing Your Roadmap? Just Tell Us..."
            name="message"
            value={message}
            onChange={(event) => {
              setMessage(event.target.value);
            }}
          />
          <Button variant="primary" size="sm" type="submit" value="Submit">
            Submit
          </Button>
        </div>
        <div>
          {success && !characterLimitExceeded && (
            <p style={{ color: 'green' }}>Thank you for your feedback!</p>
          )}
          {characterLimitExceeded && (
            <p style={{ color: 'red' }}>Enter less than 200 characters.</p>
          )}
        </div>
      </Form.Group>
    </Form>
  );
};

export default ContactForm;