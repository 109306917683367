import React, {useEffect, useState } from "react"
import { useLocation } from 'react-router-dom';
import { Card } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

const TileDetailPage = () => {
    const location = useLocation();
    const { tileItem } = location.state || {};
    const [tileData, setTileData] = useState([]);

    useEffect(() => {
        if (!tileItem) {
            const fetchData = async () => {
                try {
                    const url = location.pathname;
                    const parts = url.split("/");
                    const id = parts[parts.length - 1];

                    const result = await fetch(`https://roadmaps-backend-3b753e6da0ee.herokuapp.com/tile?id=${id}`, { mode: 'cors' });
                    const jsonResult = await result.json();
                    console.log(jsonResult);
                    setTileData(jsonResult);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };

            fetchData();
        }
    }, []);

    const tile = isValidTile(tileItem, tileData);

    return (
        <div>
            <div>
                <div style={{ alignItems: 'center' }}>
                    {tile && (
                        <Card style={{ alignItems: 'center', backgroundColor: '#e3e6e6' }}>
                            <br />
                            <Card.Img style={{ border: '2px solid #ccc', objectFit: 'contain', margin: '0 auto', height: '60%', maxWidth: '60%', justifyContent: 'center', display: 'flex', alignItems: 'center' }} src={`https://roadmaps-backend-3b753e6da0ee.herokuapp.com/${tile.flow}`} />
                        </Card>
                    )}
                    <br />
                </div>
                <FaDownload onClick={() => downloadImage(`https://roadmaps-backend-3b753e6da0ee.herokuapp.com/${tile.flow}`, tile.clickable, tile.text)}></FaDownload>
                <br /> <br /> <br />
            </div>
        </div>
    );
};

const downloadImage = (url, clickable, text) => {
    console.log(url)
    console.log(text)
    if (clickable) {
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            // Create a temporary anchor element and set its attributes
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = text; // Specify the filename for the downloaded file
    
            // Simulate a click on the link to trigger the download
            link.click();
    
            // Clean up the URL.createObjectURL() resource after the download is initiated
            URL.revokeObjectURL(link.href);
          })
          .catch((error) => {
            console.error('Error while fetching the file:', error);
          });
      }
}

function isValidTile(tileItem, tileData) {
    return tileItem ? tileItem : tileData;
}

export default TileDetailPage;
