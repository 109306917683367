import './App.css';
import HomePage from './components/HomePage';
import Footer from './components/Footer'
import Header from './components/Header'
import TileDetailPage from './components/TileDetailPage'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {

  return (
    <Router>
      <div className="App"  style={{backgroundColor: '#e3e6e6', height: '100%', width:'100%', position:'fixed', bottom: '0', overflowY:'scroll'}} >
        <Header />
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/" component={TileDetailPage} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
